<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_bildung.delete"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <generisch-stammdaten
            :v-if="eventid"
            :veranstaltung="event"
            :editable="editable"
            :isTemplate="false"
            :veranstaltungstypen="eventtypen"
          />

          <generisch-teilnehmer
            :v-if="eventid"
            :veranstaltung="event"
            :editable="editable"
            :isTemplate="false"
          />
        </div>

        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <event-details
            :v-if="eventid"
            :event="event"
            :editable="editable"
            :isTemplate="false"
          />
        </div>

        <!-- ENDE Rechte Seite -->
        <div v-if="eventid" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'event',
                      params: { eventid: eventid, anzeige: 0 },
                    }"
                    >{{ $t("global.registrations") }}</router-link
                  >
                </li>

                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'event',
                      params: { eventid: eventid, anzeige: 1 },
                    }"
                    >{{ $t("global.lecturerbooking") }}</router-link
                  >
                </li>

                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'event',
                      params: { eventid: eventid, anzeige: 2 },
                    }"
                    >{{ $t("global.appointment") }}</router-link
                  >
                </li>

                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 3 }"
                    :to="{
                      name: 'event',
                      params: { eventid: eventid, anzeige: 3 },
                    }"
                    >{{ $t("global.clearingitems") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <anmeldungsliste
                    :veranstaltung="eventid"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                    :showUebertragen="false"
                    :key="anmeldungslisteKey"
                  />
                </div>

                <div v-show="anzeige === 1">
                  <dozentenbuchungsliste
                    :veranstaltung="eventid"
                    :shown="anzeige == 1 ? true : false"
                    :editable="editable"
                  />
                </div>
                <div v-show="anzeige === 2">
                  <terminliste
                    :veranstaltung="eventid"
                    :shown="anzeige == 2 ? true : false"
                    :editable="editable"
                  />
                </div>

                <div v-show="anzeige === 3">
                  <verrechnungspositionen
                    :veranstaltung="eventid"
                    :shown="anzeige == 3 ? true : false"
                    :editable="editable"
                    @positionChanged="anmeldungslisteUpdate"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <unsaved-changes-modal
      @discard="handleDiscard"
      @stay="handleStay"
      :saveFunction="speichern"
    />

    <messagebox-enhanced
      :headerText="$t('global.startpromotion')"
      :ok="true"
      @ok="promotionStart"
    />
    <loeschen-modal id="event-loeschen-modal" @confirmed="eventloeschen" />
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";

import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import GenerischStammdaten from "@/components/Bildung/Generisch/Stammdaten";
import GenerischTeilnehmer from "@/components/Bildung/Generisch/Teilnehmer";
import EventDetails from "@/components/Bildung/Event/EventDetails.vue";

import Anmeldungsliste from "@/components/Reiter/Anmeldungsliste";
import Terminliste from "@/components/Reiter/Terminliste";
import Dozentenbuchungsliste from "@/components/Reiter/Dozentenbuchungsliste";
import Verrechnungspositionen from "@/components/Reiter/Verrechnungspositionen.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";

export default {
  name: "event",
  components: {
    HeadMenu,
    Navbar,
    LoeschenModal,
    GenerischStammdaten,
    GenerischTeilnehmer,
    EventDetails,
    Anmeldungsliste,
    MessageboxEnhanced,
    Terminliste,
    Dozentenbuchungsliste,
    Verrechnungspositionen,
    UnsavedChangesModal,
  },
  metaInfo() {},
  mixins: [page, ChangeWatcherNew],

  props: {
    eventid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      anmeldungslisteKey: 0,
      event: {
        lehrgang: {
          bezeichnung: this.$t("global.notlinked"),
          lehrgangstatus: { bezeichnung: this.$t("global.notlinked") },
        },
        verantwortlicher: { personName: this.$t("global.notlinked") },
      },
    };
  },
  computed: {
    navbarTitel() {
      return (
        this.$t("global.Event") +
        (this.event.bezeichnung ? ": " + this.event.bezeichnung : "")
      );
    },
    eventtypen: function () {
      let eventtypen = [];
      eventtypen.push({ bezeichnung: "Event", id: "174D91487D8" });
      eventtypen.push({ bezeichnung: "Prüfung", id: "174D91487D6" });
      eventtypen.push({ bezeichnung: "Diplomfeier", id: "174D91487DE" });
      return eventtypen;
    },
  },
  watch: {
    eventid: {
      handler: function (val) {
        if (val) {
          this.ladeVeranstaltung(val);
        } else {
          this.editable = this.berechtigungen.m_bildung.update;
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted: function () {},
  methods: {
    ladeVeranstaltung(eventid) {
      this.loading = true;

      Api.get("veranstaltungen/", { params: { id: eventid } })
        .then((response) => {
          this.initializeevent(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    initializeevent(event) {
      if (event) {
        this.event = event;

        if (!this.event.verantwortlicher)
          this.event.verantwortlicher = {
            personName: this.$t("global.notlinked"),
          };

        if (!this.event.lehrgang)
          this.event.lehrgang = {
            bezeichnung: this.$t("global.notlinked"),
            lehrgangstatus: { bezeichnung: this.$t("global.notlinked") },
          };

        if (!this.event.lehrgang.lehrgangstatus)
          // Datenfail in der Datenbank Lehrgang ohne Status
          this.event.lehrgang.lehrgangstatus = {
            bezeichnung: this.$t("global.notlinked"),
          };

        this.editable = this.$CONST("EVENTEDITABLESTATI").includes(
          this.event.veranstaltungsstatus.id
        )
          ? true
          : false;
      } else {
        console.error("event ist null");
      }

      //this.initializeChangewatcher(["event"]);

      this.editable = !this.berechtigungen.m_bildung.update
        ? false
        : this.editable;
    },

    promotion() {
      this.$bus.$emit("openMessageBoxEnhanced", {
        text: this.$t("global.shouldthepromotionbestarted"),
      });
    },

    promotionStart() {
      Api.put(
        "noten/promotion",
        {},
        {
          params: { event: this.event.id },
        }
      ).then(() => {});
    },

    abbrechen() {
      this.$router.push({ name: "event-liste" });
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.event);

      if (this.event.qualifikationen) {
        json.qualifikationen = this.event.qualifikationen.map((qa) => {
          return qa.id;
        });
      }

      if (this.event.themen) {
        json.themen = this.event.themen.map((t) => {
          return t.id;
        });
      }

      json.lehrgang = this.event.lehrgang?.id;
      json.veranstaltungsstatus = this.event.veranstaltungsstatus?.id;
      json.veranstaltungstyp = this.$CONST("VERANSTALTUNGSTYPEN").EVENT;
      json.verantwortlicher = this.event.verantwortlicher?.id;
      json.periode = this.event.periode?.id;

      if (this.event.id) {
        Api.put("veranstaltungen/", json, { params: { id: this.event.id } })
          .then((response) => {
            this.initializeevent(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.eventsuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.post("veranstaltungen/", json)
          .then((response) => {
            //this.initializeevent(response.data);
            this.$router.replace({
              name: "event",
              params: {
                eventid: response.data.id,
                anzeige: 0,
              },
            });
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.eventsuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "event-loeschen-modal");
    },
    anmeldungslisteUpdate() {
      this.anmeldungslisteKey++;
    },
    eventloeschen() {
      let selectedId = [this.eventid];
      Api.delete("veranstaltungen/", {
        params: { ids: selectedId.join(",") },
      }).then(() => {
        this.$router.replace({ name: "event-liste" });
      });
    },
  },
};
</script>

<style></style>
