<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>

              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable && berechtigungen.m_umfragen.update"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button
                    v-if="berechtigungen.m_umfragen.read"
                    class="btn btn-primary mr-2"
                    @click="abbrechen"
                  >
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                  <button
                    v-if="hasQR && berechtigungen.m_umfragen.read"
                    class="btn btn-primary mr-2"
                    @click="openQrModal"
                  >
                    {{ $t("skjv.showqr") }}
                  </button>
                  <button
                    v-if="!hasQR && berechtigungen.m_umfragen.update"
                    class="btn btn-primary mr-2"
                    @click="openQrModal"
                  >
                    {{ $t("skjv.createqr") }}
                  </button>
                  <button
                    v-if="berechtigungen.m_umfragen.create"
                    class="btn btn-primary mr-2"
                    @click="copy"
                  >
                    <font-awesome-icon icon="fa-duotone fa-copy" class="mr-2" />
                    {{ $t("global.Copy") }}
                  </button>
                  <button
                    class="btn btn-primary mr-2"
                    @click="openEvaluation"
                    v-if="
                      questionaire.questions.length != 0 &&
                      berechtigungen.m_umfragen.read
                    "
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-chart-gantt"
                      class="mr-2"
                    />
                    {{ $t("global.Evaluation") }}
                  </button>
                  <button
                    class="btn btn-primary mr-2"
                    @click="vorlageWaehlen"
                    v-if="editable && berechtigungen.m_umfragen.update"
                  >
                    {{ $t("global.selecttemplate") }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_umfragen.delete && editable"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span> {{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START Linke Seite -->
        <div class="col-xl-12 pr-3" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="2"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-4">
                    <single-select-person
                      id="ansprechpartner"
                      :firma="questionaire.firma"
                      :person="questionaire.creator"
                      :label="$t('global.creator')"
                      :allowOpen="isDozent ? false : true"
                      :editable="editable"
                      :required="true"
                      @confirmed="setPerson"
                      :tabindex="1"
                    />
                  </div>
                  <div class="col-xl-4">
                    <single-select-veranstaltung
                      id="termin-veranstaltung"
                      :veranstaltung="questionaire.event"
                      :label="$t('global.event')"
                      :allowOpen="berechtigungen.m_bildung.read"
                      :editable="editable"
                      @confirmed="setVeranstaltung"
                    />
                  </div>
                  <div class="col-xl-4">
                    <single-select-lehrgang
                      id="lehrgang-select"
                      :label="$t('global.educationcourse')"
                      :lehrgang="questionaire.course"
                      :allowOpen="false"
                      :editable="editable"
                      @confirmed="setLehrgang"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <input
                          v-model="questionaire.designation"
                          v-bind:class="{ 'border-danger': !passed }"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label v-bind:class="{ 'text-danger': !passed }">{{
                          $t("global.designation")
                        }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="questionaire.description"
                        class="form-control"
                        placeholder=" "
                        rows="5"
                        :readonly="!editable"
                      ></textarea>
                      <label>{{ $t("global.description") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ENDE DATEN -->
        </div>

        <!-- START Rechte Seite -->

        <div
          v-if="questionaire.id"
          class="col-xl-12 pl-3"
          :class="{ 'col-xl-12': !showNavbar }"
        >
          <!-- START Fragen -->

          <div class="row">
            <div class="col-xl-12">
              <fragen
                :editable="editable"
                :questionaireID="questionaire.id"
                :questions="questionaire.questions"
                @questionsLoeschen="questionsLoeschen"
                @questionsHinzufuegen="questionsHinzufuegen"
                @reloadQS="reloadQS"
              />
            </div>
          </div>

          <!-- ENDE Rechte Seite -->
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <umfrage-vorlage-modal @confirmed="vorlageSetzen" />

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="questionaire-loeschen-modal"
      @confirmed="questionaireloeschen"
    />

    <qr-message headerText="QR" />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import QuestionaireApi from "@/QuestionaireApi";
import store from "@/store";

import page from "@/mixins/Page";
import dashboard from "@/mixins/QuestionaireDashboard";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import SingleSelectLehrgang from "@/components/global/SingleSelectLehrgang.vue";
import Fragen from "@/components/Geschaeftspartner/Fragen.vue";
import UmfrageVorlageModal from "@/components/Modals/UmfrageVorlageModal";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import QrMessage from "@/components/Modals/QrCodeMessageBox";

export default {
  name: "Questionaire",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoeschenModal,
    SingleSelectPerson,
    SingleSelectVeranstaltung,
    SingleSelectLehrgang,
    Fragen,
    QrMessage,
    UmfrageVorlageModal,
    UnsavedChangesModal,
  },
  mixins: [page, dashboard, ChangeWatcherNew],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: true,
      verantwortlicher: [{}],
      questionaire: {
        id: null,
        creator: {},
        event: {},
        course: {},
        verantwortlicher: [{}],
        firma: {},
        typ: {},
        status: {},
        questions: [],
        qrcode: null,
        vorlage: null,
      },
      hasQR: false,
      verantwortliche: [],
      isDozent: false,
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },

    questiontypes: {
      get() {
        return this.$store.state.questionaire.questiontypes;
      },
    },

    navbarTitel: function () {
      if (this.id) {
        return this.$t("global.survey");
      } else {
        return this.$t("global.newsurvey");
      }
    },
  },
  watch: {},
  created() {
    this.loading = true;

    if (this.id) this.loadQuestionaire();

    if (!this.id)
      this.questionaire.creator = this.angemeldeterMitarbeiter.person;
    this.questionaire.creator.personName =
      this.angemeldeterMitarbeiter.nachname +
      " " +
      this.angemeldeterMitarbeiter.vorname;
    this.setPerson(this.questionaire.creator);
    this.loading = false;
  },
  mounted() {
    console.log(this.$CONST("GRUPPEN").INSTRUCTOR);
    if (
      this.gruppen.find(
        (gruppe) => gruppe.id == "1774DD1D796" // "Dozent" this.$CONST("GRUPPEN").INSTRUCTOR
      )
    ) {
      this.isDozent = true;
    }
  },
  methods: {
    async loadQuestionaire() {
      if (this.id) {
        await QuestionaireApi.get("assessment/", {
          params: { id: this.id },
        }).then((response) => {
          this.initializeQuestionaire(response.data);

          this.loading = false;
        });
      }
    },
    copy() {
      QuestionaireApi.get("assessment/", {
        params: { idsToCopy: this.id },
      }).then((response) => {
        this.$router.push({
          name: "questionaire",
          params: { id: response.data.id, anzeige: 0 },
        });
        this.initializeQuestionaire(response.data);
      });
    },

    abbrechen() {
      this.$router.push({ name: "questionaire-liste" });
    },

    initializeQuestionaire(questionaire) {
      if (questionaire) {
        this.questionaire = questionaire;

        this.verantwortlicher = this.questionaire.verantwortlicher;
        this.questionaire.creator =
          questionaire.creator || this.angemeldeterMitarbeiter.person;
        this.questionaire.creator.personName =
          questionaire.creator.name + " " + questionaire.creator.vorname;
        this.questionaire.event = questionaire.event || {
          bezeichnung: this.$t("global.notlinked"),
        };
        this.questionaire.course = questionaire.course || {
          bezeichnung: this.$t("global.notlinked"),
        };
        this.questionaire.qrcode = questionaire.qrcode;
        if (!this.questionaire.qrcode.includes("null")) this.hasQR = true;
        //this.questionaire.questions = questionaire.questions;
        this.$set(this.questionaire, "questions", questionaire.questions);
        this.editable = true;

        if (this.berechtigungen.m_umfragen.update == false)
          this.editable = false;

        //this.initializeChangewatcher(["questionaire"]);
      } else {
        console.error("questionaire ist null");
      }
    },

    speichern() {
      if (this.loading) return;
      this.loading = true;

      this.questionaire.verantwortlicher = this.verantwortlicher;
      var json = this.questionaire;

      // return new Promise((resolve, reject) => {
      if (!this.id) {
        QuestionaireApi.post("assessment/", json)
          .then((response) => {
            this.initializeQuestionaire(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.questionairesavedsuccessfully"),
            });

            this.$router.replace({
              name: "questionaire",
              params: { id: response.data.id, anzeige: 0 },
            });
            // resolve(response);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            // reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        QuestionaireApi.put("assessment/" + this.id, json)
          .then((response) => {
            this.loadQuestionaire();
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.activitysavedsuccessfully"),
            });
            // resolve(response);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            // reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
      // });
    },

    openQrModal() {
      if (this.questionaire.qrcode.includes("null")) {
        this.createQR().then((response) => {
          let data = {};
          data.img = response.data;
          data.link = this.$CONST("CONFIG").BASEURL + "/qs/" + this.id;
          data.headerText = "QR";
          this.$bus.$emit("openQrCodeMsgBox", data);
        });
      } else {
        let data = {};
        data.img = this.questionaire.qrcode;
        data.link = this.$CONST("CONFIG").BASEURL + "/qs/" + this.id;
        data.headerText = "QR";
        this.$bus.$emit("openQrCodeMsgBox", data);
      }
    },

    createQR() {
      QuestionaireApi.post("qr/?id=" + this.id)
        .then((response) => {
          this.loadQuestionaire();
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.activitysavedsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "questionaire-loeschen-modal");
    },

    questionaireloeschen() {
      QuestionaireApi.delete("assessment/" + this.id).then(() => {
        this.$router.push({ name: "questionaire-liste" });
      });
    },

    setVeranstaltung(veranstaltung) {
      this.$set(this.questionaire, "event", veranstaltung);
    },

    setLehrgang(lehrgang) {
      this.$set(this.questionaire, "course", lehrgang);
    },

    setVerantwortlicher(person) {
      this.verantwortlicher = person;
    },

    setPerson(person) {
      this.questionaire.creator = person;
    },

    setFirma(firma) {
      this.questionaire.firma = firma;
    },
    questionsLoeschen(question) {
      QuestionaireApi.delete("question/" + question.id)
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.activitysavedsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loadQuestionaire();
          this.loading = false;
        });
    },
    questionsHinzufuegen() {
      this.questionaire.questions.push({ id: "", bezeichnung: "" });
    },
    reloadQS() {
      this.loadQuestionaire();
    },
    openEvaluation() {
      const url = this.$router.resolve({
        name: "Auswertung",
        params: {
          id: this.questionaire.id,
        },
      }).href;
      window.open(url, "_blank");
    },
    vorlageWaehlen() {
      this.$bus.$emit("open-umfrage-vorlage-modal");
    },
    vorlageSetzen(vorlageId) {
      this.questionaire.vorlage = vorlageId;
      this.speichern();
    },
  },
};
</script>

<style></style>
